// Основной блок

.main-sidebar {
    display: block;
    width: 100%;
    padding: 0.5em 1.25em;
    background-color: white;
    height: 60px;
    border-bottom: 1px solid #D9D9D9;

    &__nav {
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 1.25em;
    }

    &__group-btn {
        display: flex;
        gap: 0.63em;
    }

    &__left-box {
        display: flex;
        gap: 40px;
         width: 100%;
        align-items: center;
    }

    &__right-box {
        display: flex;
        gap: 1em;
        align-items: center;
    }
}

// Кнопки

.notifications__btn,
.message__btn,
.upload__btn,
.task__btn,
.setting__btn {
    display: inline-block;
    position: relative;
    background-color: #F5F5F5;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    border: none;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &:hover {
        background-color: #CCCCCC;
        transition: background-color 0.3s;
    }

}

.notifications {
    &::before {
        content: "9";
        color: white;
        position: absolute;
        top: -2px;
        right: -2px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        background-color: #48A8FE;
        border-radius: 50%;
    }
}

.notifications__btn {
    background-image: url('./img/bell.svg');
}

.task__btn {
    background-image: url('./img/task.svg');
}

.upload__btn {
    background-image: url('./img/upload.svg');
}

.message__btn {
    background-image: url('./img/message.svg');
}

.setting__btn {
    background-image: url('./img/setting.svg');
}

// Поле ввода
.input-field {
    border: 1px solid #F0F0F0;
    height: 32px;
    border-radius: 4px;
    width: 100%;
    min-width: 100px;
    max-width: 446px;
    padding: 0.63em 0.63em 0.63em 2.3em;
    background-image: url("./img/search.svg");
    background-repeat: no-repeat;
    background-position: 15px center;
    font-family: 'Inter';
    font-size: 0.9em;
    color: black;
    cursor: pointer;

    &::placeholder {
        font-family: 'Inter';
        font-size: 0.9em;
        color: #D9D9D9;
    }

    &:focus {
        outline: 1px solid grey;
    }
}

// Блок профиля

.profile-box {
    display: flex;
    align-items: center;
    gap: 0.5em;

    &__btn {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        cursor: pointer;
        background-image: url('./img/profile.svg');
        background-position: center;
        background-repeat: no-repeat;
        border: none;
    }

    &__user-name {
        font-family: 'Inter';
        font-size: 0.9em;
    }
}

.training-nav {
    display: flex;
    gap: 40px;
    align-items: center;

    &__item {
        font-size: 14px;
    }

    &__item_active {
        font-size: 20px;
    }
}