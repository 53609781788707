.sidebar {

    &__link {
        width: 100%;
        display: inline-block;
        font-family: 'Inter';
        font-size: 0.9em;
        
        &_grey {
            display: inline-block;
            font-family: 'Inter';
            font-size: 0.9em;
            margin-left: 0.5em;
            color: #B5ABAB;
        }
    }

    &__text {
        display: inline-block;
            font-family: 'Inter';
            font-size: 0.9em;
            margin-left: 0.5em;
            color: #B5ABAB;
    }

    &__link-box {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-right: 1.4em;

        &_active {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding-right: 1.4em;
            color: #1890FF;
        }

        &:hover {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding-right: 1.4em;
            color: #1890FF;
            background-color: #E6F7FF;
            transition: background-color 0.3s;
        }
    }

    &__drop {
        display: block;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s;

        &_active {
            display: block;
            overflow: hidden;
            max-height: 1000px;
            transition: max-height 1s;
        }
    }

    &__list-item {
        padding: 1em 1em 1em 3.5em;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;

        &_active {
            padding: 1em 1em 1em 3.5em;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            color: #1890FF;
            background: #E6F7FF;
            border-right: 2px solid #2900ccf7;
        }

        &:hover {
            padding: 1em 1em 1em 3.5em;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            color: #1890FF;
            background: #E6F7FF;
            transition: background-color 0.3s;
        }
    }
}

.boxx {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;
}

.side-icon-box {
    min-width: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-drop {
    margin-left: auto;
    transform: rotate(0deg);

    &_active {
        margin-left: auto;
        transform: rotate(180deg);
    }
}
