.recommendation {
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    gap: 30px;
    margin-bottom: 48px;

    .title {
        color: #969798;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    &__item {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            display: block;
            max-width: 100%;
            width: 100%;
            height: 1px;
            background-color: #F0F0F0;
            margin-top: 16px;
        }
    }

    &__link {
        font-size: 20px;
        color: #1890FF;
        font-weight: 500;
    }
}