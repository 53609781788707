.breadcrumb-box {
    padding: 12px 32px 0px;
}

.settings {

    &__content {
        display: flex;
        flex-direction: column;
        gap: 18px;
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;

        & .title {
            padding-left: 24px;
        }

    }

    &__link {
        text-align: left;
        // display: inline-block;
        font-weight: 400;
    }

    &__link-box {
        padding: 8px;
      
        &_active {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            border-bottom: 1px solid #D3D3D3;
        }

        & .settings__link {
            font-size: 16px;
        }
    }

    

    &__box {
        transition: all 0.5s;
        height: 100%;
        // max-height: 720px;
        min-width: 280px;
        max-width: 280px;
        background-color: white;
        border-radius: 16px;
        padding: 0px;
        overflow: hidden;
    }

    &__box_active {
        transition: all 0.5s;
        height: 100%;
        max-height: 0px;
        min-width:0px;
        max-width: 0px;
        background-color: white;
        border-radius: 16px;
        padding: 0px;
        overflow: hidden;
    }

    &__nav {
        margin: 16px;
        min-width: 240px;
        width: 232px;
    }


    &__main-box {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: auto;
        width: 100%;
        border-radius: 16px;
        overflow: hidden;
        min-height: 720px;

        &_white {
            min-height: 720px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            height: auto;
            width: 100%;
            border-radius: 16px;
            overflow: hidden;
            background-color: white;
            padding: 16px;

            .settings_connection_header {
                background-color: #fff;
            }


        }
    }

    &__list-item {
        padding: 8px;
        cursor: pointer;
        font-size: 14px;
        width: 100%;
        transition: background-color 0.1s;

        &_active {
            padding: 8px;
            cursor: pointer;
            background-color: #CDFDE0;
            color: #797979;
            border-radius: 16px;
            width: 100%;
            font-size: 14px;
        }

        &:hover {
            background-color:  #CDFDE0;
            color: #797979;
            transition: background-color 0.3s;
            border-radius: 16px;
            width: 100%;
        }
    }



    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & .settings__link {
            font-weight: 400;
        }
    }



    &__drop {
        margin-left: 8px;
    }
}

.flex {
    display: flex;
    gap: 24px;
}