.checkbox-container {
    .checkbox {
        opacity: 0;
        position: fixed;
        width: 1px;
        height: 1px;
        clip: rect(1px, 1px, 1px, 1px);
    }
}

.checkbox-element {
    display: inline-block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    vertical-align: middle;
}

.checkbox-element::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    left: 344px;
    top: 321px;
    // authorization box differences
    border: none;
    background-color: #D9D9D9;
    //
    border-radius: 2px;
    margin-right: 5px;
    vertical-align: middle;
}

.checkbox:checked~.checkbox-element::before {
    // differences
    background-image: url("./img/checkbox-white.svg");
    background-color: #1890FF;
    //
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
}