.knowledge-server {
   width: 100%; 
   padding: 16px;

   .title {
    margin-top: 16px;
    font-size: 24px;
   }

   .text {
    margin-top: 24px;
   }

   &__cards-box {
      align-items: center;
    margin-top: 24px;
    display: flex;
    column-gap: 16px;
    row-gap: 24px;
    flex-wrap: wrap;
   } 

   .btn__next {
      width: 16px;
      height: 27px;
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      background-color: inherit;
      cursor: pointer;
      background-image: url('./img/next.svg');
  }
}