.education {
    width: 100%;
    height: 100%;
}

.article {
    width: 360px;
    height: auto;
    min-height: 240px;
    padding: 16px 24px;
    background-color: white;
    border-radius: 16px;

    .title {
        margin-top: 0;
        text-transform: uppercase;
        font-size: 24px;
    }

    .btn__next {
        cursor: pointer;
        background-image: url('./img/arrow-next.svg');
        width: 25px;
        height: 24px;
        border: none;
        background-color: inherit;
        margin-top: 37px;
    }
}



.article-template__content {

    .line {
        margin-bottom: 8px;
    }

    .title-box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-top: 16px;

    }

    .toolbar-filter {
        display: flex;
        column-gap: 24px;
        row-gap: 16px;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        flex-wrap: wrap;

        .btn__green {
            margin: 0;
            max-width: 200px;
            min-width: 200px;
        }

        .select-group {
            display: flex;
            align-items: center;
            column-gap: 24px;
            row-gap: 16px;
            flex-wrap: wrap;

            .selector_wrapper {
                display: flex;
                align-items: center;
                background-color: white;
                padding: 0;
                border-radius: 1em;
                width: 200px;
                min-width: 200px;
                min-height: 32px;
                height: auto;
                min-height: 32px;
                padding: 0;

                .dropdown_selector,
                .css-b62m3t-container,
                .css-13cymwt-control,
                .css-t3ipsp-control {
                    box-sizing: border-box;
                    padding: 0;
                    border-radius: 16px;
                    height: auto;
                    min-height: 32px;
                    height: auto;
                }

                .css-1xc3v61-indicatorContainer,
                .css-15lsz6c-indicatorContainer {
                    min-height: 32px;
                    padding-top: 4px;

                }

                .css-1fdsijx-ValueContainer,
                .css-1hb7zxy-IndicatorsContainer {
                    height: auto;
                }
            }

            .selector_wrapper {
                border: 1px solid #F0F0F0;
            }
        }
    }

    .toolbar-info-box {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 8px;

        .toolbar-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 24px;
            flex-wrap: wrap;

            .date-picker {
                max-width: 168px;
            }

            .text_grey {
                white-space: nowrap;
                font-size: 12px;
                color: #888888;
            }

            .select-box {
                display: flex;
                gap: 16px;
                align-items: center;

                .date-picker {
                    border: 1px solid #F0F0F0;
                }
            }

            .tag-search-box {
                display: flex;
                gap: 24px;
                align-items: center;
                flex-wrap: wrap;
                width: auto;

                .btn__bej {
                    max-width: 160px;
                    min-width: 160px;
                    margin-top: 0;
                }

                &__content {
                    gap: 16px;
                    align-items: center;
                    width: auto;
                    display: flex;

                    .input-field-text {
                        height: 32px;
                        max-width: 200px;
                        min-width: 200px;
                    }
                }
            }
        }

        .toolbar-bottom {
            width: 100%;
            display: flex;
            gap: 16px;

            .text_grey {
                white-space: nowrap;
                font-size: 12px;
                color: #888888;
            }

            .tag-box_green {
                display: flex;
                align-items: center;
                width: auto;
                padding-left: 8px;
                padding-right: 8px;
                background-color: #F2FFE6;
                border-radius: 16px;
                gap: 10px;
                height: 20px;



            }
        }
    }

    .toolbar-group-btn {
        width: 100%;
        display: flex;
        gap: 24px;
        margin-top: 24px;
        cursor: pointer;

        .btn-item-box {
            width: 33.33%;
            display: flex;
            align-items: center;

            &:nth-child(2) {
                justify-content: center;
            }

            &:nth-child(3) {
                justify-content: flex-end;
            }
        }

        .btn__green {
            max-width: 200px;
            margin: 0;
        }

        .group-btn {
            display: flex;
            gap: 16px;
            align-items: center;
        }
    }
}

.btn__round-blue {
    width: 32px;
    height: 32px;
    border: none;
    background-color: inherit;
    background-image: url('./img/btn-add-round.svg');
    background-size: 32px;
    cursor: pointer;

}

.btn__close-round {
    width: 13px;
    height: 13px;
    background-image: url('./img/close-round.svg');
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: inherit;
    cursor: pointer;
}


 .title-box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;

        .title_grey {
            color: #000000;
            font-size: 24px;
            font-weight: 500;
        }
    }

.add-text-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 48px;

   

    .textarea-box {
        width: 100%;
        height: auto;
        position: relative;

        .btn__close-round {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 17px;
            height: 17px;
            background-size: 17px;
        }

        .textarea__add-text {
            border: 1px solid #F0F0F0;
            padding: 16px;
            resize: none;
            max-width: 100%;
            width: 100%;
            height: 240px;
            border-radius: 16px;
            font-size: 16px;

            &::placeholder {
                color: #D9D9D9;
                font-size: 16px;
            }

            &:focus {
                outline: none;
                border: 1px solid #F0F0F0;
            }
        }
    }
}

.container-article {
    overflow: hidden;
    width: 100%;
    height: auto;
    border-radius: 16px;
    border: 1px solid #F0F0F0;
    margin-top: 24px;
    position: relative;



    .article-template__img-container {
        margin: 0;

        img {
            display: inline-block;
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }

    .yellow-information-box {
        margin-top: 0;

        .text {
            margin: 0;
        }

        .text[contenteditable="true"] {
            outline: none;
        }
    }

    .text {
        margin: 16px;
    }

    .text[contenteditable="true"] {
        outline: none;
    }


    .btn__close-round {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        z-index: 1;
    }

    .text {
        margin: 16px;
    }
}