.modal-article-add-element {
    max-width: 560px;
    min-width: 560px;
    min-height: 360px;
    height: auto;
    margin-top: 160px;
    position: absolute;
    left: calc(50% - 280px);
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    padding: 24px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5000;

    .title {
        font-size: 16px;
        font-weight: 500;
        padding: 0;
        margin: 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: auto;
        gap: 40px;
        margin-top: 40px;

        
    }

    .btn-box {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 40px;

        .btn__green {
            max-width: 200px;
            margin: 0;
        }
    }

    .checkbox-item-box {
        display: flex;
        gap: 24px;

        .text {
            font-size: 14px;
            margin-top: 0;

            .text_grey {
                font-size: 14px;
                color: #AAAAAA;
            }
        }
    }
}