.autorization {
    display: flex;
    flex-direction: column;
    max-width: calc(560px + 96px);
    width: 100%;
    height: auto;
    background-color: #F5F5F5;
    padding-left: 48px;
    padding-right: 48px;
    justify-content: space-between;

    &__footer {
        width: 100%;
        max-width: 1440px;
        padding: 40px 48px;
        background-color: inherit;
        height: auto;
        margin-top: auto;

    }

    &__input-field {
        width: 100%;
        max-height: 56px;
        height: 100%;
        font-family: 'Inter';
        font-size: 14px;
        padding: 16px 20px;
        margin-top: 32px;
        border: 1px solid #F0F0F0;
        border-radius: 16px;

        &::placeholder {
            font-size: 14px;
            font-family: "Inter";
            color: #D9D9D9;
        }

        &_err {
            border: 1px solid #FF4D4F;


        }
    }
}

.input-with-btn {
    width: 100%;
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    display: flex;

    &_pas {
        width: 100%;
        display: flex;
        position: relative;
        width: 100%;
        height: auto;
        display: flex;

        &::after {
            content: "Введеные пароли не совпадают";
            z-index: 100;
            display: inline-block;
            position: absolute;
            width: 100%;
            bottom: -26px;
            color: #848484;
            height: 16px;
            font-family: "Inter";
            font-size: 12px;
        }
    }

    &_password {
        width: 100%;
        display: flex;
        position: relative;
        width: 100%;
        height: auto;
        display: flex;

        &::after {
            content: "Пароль должен содержать не менее 8 символов и включать в себя цифры и латинские буквы нижнего (a...z) и верхнего (A...Z) регистра";
            z-index: 100;
            display: inline-block;
            position: absolute;
            width: 100%;
            bottom: -40px;
            color: #D9D9D9;
            height: 16px;
            font-family: "Inter";
            font-size: 12px;
        }
    }

    &_active {
        width: 100%;
        display: flex;
        position: relative;
        width: 100%;
        height: auto;
        display: flex;

        &::after {
            content: "Данный логин уже зарегистрирован в системе";
            z-index: 100;
            display: inline-block;
            position: absolute;
            width: 100%;
            bottom: -26px;
            color: #FF4D4F;
            height: 16px;
            font-family: "Inter";
            font-size: 12px;
        }
    }


}


.checkbox {

    &__box {
        display: flex;
        justify-content: space-between;
        margin-top: 23px;

        & .text_red {
            margin-top: 0;
            color: #FF4D4F;
        }

    }


}

.text {
    font-size: 14px;
    font-family: 'Inter';

    &_blue {
        display: inline-block;
        color: #1890FF;
    }

    &_red {
        color: #FF4D4F;
        margin-top: 24px;
    }
}

.title {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 400;
}

.input-with-btn {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
}

.hide,
.show {
    cursor: pointer;
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    bottom: 14px;
    right: 14px;
    background-image: url("./img/hide.svg");
    background-size: 20px;
    background-color: inherit;
    border: none;
    background-position: center;
    background-repeat: no-repeat;
}

.show {
    background-image: url("./img/show.svg");
}

.check-your-mail__box {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.checkbox__box_registration {
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}