.autorization {
    display: flex;
    flex-direction: column;
    max-width: 550px;
    width: 100%;
    height: auto;
    background-color: #F5F5F5;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;


    &__footer {
        width: 100%;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        background-color: inherit;
        height: auto;
        margin-bottom: 36px;
        margin-top: 36px;
    }

    &__input-field {
        width: 100%;
        max-height: 56px;
        height: 100%;
        font-family: 'Inter';
        font-size: 14px;
        padding: 16px 20px;
        margin-top: 32px;
        border: 1px solid #F0F0F0;
        border-radius: 16px;

        &::placeholder {
            font-size: 14px;
            font-family: "Inter";
            color: #D9D9D9;
        }

        &_err {
            border: 1px solid #FF4D4F;
                &::after {
                content: "Обязательное поле";
                background-color: #FF4D4F;
                color: #9c2424;
                z-index: 100;
                display: inline-block;
                position: absolute;
                width: 100px;
                height: 16px;
                font-family: "Inter";
                font-size: 12px;
            }
        }

        &:required:valid {
            border-color: #ffffff;
          }
        
    }
}

.input-with-btn {
    width: 100%;
    height: auto;
    display: flex;
}

.text {
    font-size: 14px;
    font-family: 'Inter';

    &_blue {
        display: inline-block;
        color: #1890FF;
    }

    &_red {
        color: #FF4D4F;
    }
}



.title {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 400;
}

.authorization-checkbox {
    &__label {
        display: flex;
        max-width: 100px;
    }
}

.authorization-checkbox__text {
    display: inline-block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    vertical-align: middle;
}

.authorization-checkbox__text::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    left: 344px;
    top: 321px;
    border: 1px solid black;
    background-color: inherit;
    border-radius: 2px;
    margin-right: 5px;
    vertical-align: middle;
}

.authorization-checkbox:checked~.authorization-checkbox__text::before {
   // background-image: url("./img/checkbox.png");
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
}

.authorization-checkbox {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
}

.btn__green,
.btn__blue,
.btn__grey,
.btn__bej {
    width: 100%;
    min-height: 32px;
    font-size: 14px;
    max-width: 200px;
    border-radius: 16px;
    background-color: #27BC62;
    border: none;
    margin-top: 32px;
    color: white;
    font-family: "Inter";
    min-width: 120px;
    cursor: pointer;

    &[disabled] {
        position: relative;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            background-color: white;
            position: absolute;
            border-radius: 16px;
            opacity: 0.5;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &:hover {
            box-shadow: none;
        }

    }

    &:hover {
        -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
    }
}

.btn__bej {
    background-color: #D1A77E;
}
.btn__blue {
    background-color: #1890FF;
    margin-top: 0;
}

.btn__grey {
    background-color: #F0F0F0;
    color: black;
}

.back-button {
    border: none;
    background: none;
    cursor: pointer;
}


.date-picker {
    display: flex;
    background: white;
    border-radius: 16px;
    justify-content: space-around;
    align-items: center;
    height: 32px;
    padding-right: 16px;
    cursor: pointer;
}

.date-picker input {
    background: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
}

.date-picker input:focus{
    border: none;
    outline: none;
}

.first-date_picker{
    text-align: end;
}

.selector_wrapper  {
    width: 320px;
    height: auto;
    background-color: white;
    padding: 0;
    border-radius: 16px;
    padding-left: 16px;

    .css-13cymwt-control, .css-t3ipsp-control {
        height: auto;
        border-radius: 16px;
        max-height: 100%;
    }
}


.css-1u9des2-indicatorSeparator {
    display: none;
}

.dropdown_selector {
    width: 100%;
}


.input-field-text {
    border: 1px solid #F0F0F0;
    height: 48px;
    border-radius: 16px;
    width: 100%;
    min-width: 100px;
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    font-family: 'Inter';
    font-size: 14px;
    color: black;
    cursor: pointer;

    &::placeholder {
        font-family: 'Inter';
        font-size: 14px;
        color: #D9D9D9;
    }

    &:focus {
        outline: 1px solid grey;
    }
}

.link__blue {
    text-decoration: none;
    color: #1890FF;
} 

.dropdown_selector {
    border: none;
    outline: none;
    font-size: 14px;
}

.dropdown_selector>div {
    border: none;
    outline: none;
    box-shadow: none;
    font-size: 14px;

}

.dropdown_selector>div:hover {
    border: none;
    outline: none;
    font-size: 14px;

}

.selector_title {
    font-size: 14px;
}

.loader_wrapper {
    width: 100%; 
    height: 300px;
    display: flex; 
    justify-content: center; 
    align-items: center;

    &.product_list {
        width: 100%;
        height: 100%;
        border: none;
    }
}

.blackout {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: fixed;
    overflow: auto;


    &::before {
        content: "";
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(0, 0, 0, 0.3);
        position: absolute;
        z-index: 1000;
        opacity: 0.3;
    }

}

.blackout {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100vh;
}


.radio {
    cursor: pointer;
    background-color: white;
    color: black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50%;
    width: 16px;
   min-width: 16px;
    height: 16px;

    border: 1px solid #999;
    outline: none;
    position: relative;
    top: 4px;
    &:checked {
        cursor: pointer;
        min-width: 16px;
        width: 16px;
        height: 16px;
        border: 4px solid #FBCD10;
        background-color: black;
      }
}