.article-min-box {
    display: flex;
    flex-direction: column;
    padding: 8px 32px;

    .article-box {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        gap: 24px; 
    }

    .toolbar-top {
        display: flex;
        width: 100%;
        column-gap: 40px;
        row-gap: 16px;
         flex-wrap: wrap;

        .filter-group, .btn-group {
            display: flex;
            column-gap: 24px;
            row-gap: 16px;
            align-items: center;
            width: auto;
            flex-wrap: wrap;

        }

        .btn__green, .btn__bej{
            margin: 0;
            height: 32px;
            min-width: 200px;
            font-size: 12px;
        }
    }

    .selector_wrapper {
        border: 1px solid #F0F0F0;
        display: flex;
        align-items: center;
        background-color: white;
        padding: 0;
        border-radius: 1em;
        width: 200px;
        min-width: 200px;
        min-height: 32px;
        height: auto;
        min-height: 32px;
        padding: 0;
        font-size: 12px;
        color: black;

        .dropdown_selector,
        .css-b62m3t-container,
        .css-13cymwt-control,
        .css-t3ipsp-control {
            box-sizing: border-box;
            padding: 0;
            border-radius: 16px;
            height: auto;
            min-height: 32px;
            height: auto;
            color: black;
        }

        .css-1xc3v61-indicatorContainer,
        .css-15lsz6c-indicatorContainer {
            min-height: 32px;
            padding-top: 4px;
        }

        .css-1jqq78o-placeholder {
            font-size: 12px;
            color: black;
        }

        .css-1fdsijx-ValueContainer,
        .css-1hb7zxy-IndicatorsContainer {
            height: auto;
        }
    }

}

.select-box {
    display: flex;
    align-items: center;
    gap: 24px;

    .text {
        white-space: nowrap;
        font-size: 12px;
    }




}