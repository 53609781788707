.admin-autorization-box {
    width: 100%;
    height: 100%;

    &__header {
        display: flex;
        margin-top: 72px;
        width: 100%;
        justify-content: flex-start;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 24px;
        padding-right: 24px;
        align-items: center;
        gap: 8px;

        .title {
            font-size: 20px;
            background-image: linear-gradient(176deg, rgba(36, 57, 176, 1) 0%, rgba(141, 24, 195, 1) 100%);
            font-weight: 700;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .autorization {
        background-color: white;
        margin-top: 0;
    }

    .img-and-registation {
        display: flex;
        padding: 32px 24px;
        border: 1px solid #F0F0F0;
        border-radius: 16px;
        background-color: white;
        margin-top: 48px;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;

        .img-and-title {
            display: flex;
            flex-direction: column;
            gap: 38px;
            align-items: center;

            .title {
                background-image: linear-gradient(176deg, rgba(36, 57, 176, 1) 0%, rgba(141, 24, 195, 1) 100%);
                font-size: 28px;
                font-weight: 700;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .banner-img {
                max-width: 460px;
                width: 100%;
                height: auto;
                min-width: 100px;
            }
        }
    }
}