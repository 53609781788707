.autorization {

    &__input-field {
        width: 100%;
        max-height: 56px;
        height: 100%;
        font-family: 'Inter';
        font-size: 14px;
        padding: 16px 20px;
        margin-top: 32px;
        border: 1px solid #F0F0F0;
        border-radius: 16px;

        &::placeholder {
            font-size: 14px;
            font-family: "Inter";
            color: #D9D9D9;
        }

        &_err {
            display: block;
            width: 100%;
            max-height: 56px;
            height: 100%;
            font-family: 'Inter';
            font-size: 14px;
            padding: 16px 20px;
            margin-top: 32px;
            border: 1px solid #F0F0F0;
            border-radius: 16px;
            border: 1px solid #FF4D4F;
            position: relative;

            &::after {
                content: "";
                background-image: url('./img/checkbox.png');
                background-color: #FF4D4F;
                color: #9c2424;
                z-index: 100;
                display: block;
                position: absolute;
                width: 100px;
                height: 16px;
                font-family: "Inter";
                font-size: 12px;
            }
        }
    }
}


.blue {
    color: #1890FF;
}

.input-with-btn {
    display: flex;
}

.text {
    font-size: 14px;
    font-family: 'Inter';

    &_blue {
        display: inline-block;
        color: #1890FF;
    }

    &_red {
        color: #FF4D4F;
    }
}

.title {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 400;
}

.right-header-box {

    & .text {
        display: inline-block;
        min-width: 127px;
    }

    & .blue {
        display: inline-block;
        min-width: 127px;
        transition: all 0.5s;
    }
}