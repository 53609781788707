.breadcrumb {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    color: #B0AFAF;
    
    &__nav {
        display: block;
       width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        color: #888888;
        font-size: 12px;
    }

    &__link {
        &::after, &::before {
            content: "";
            display: inline-block;
            background-image: url("./img/arrow.svg");
            width: 7px;
            height: 12px;
            vertical-align: middle;
            margin-left: 8px;
            margin-right: 8px;
            color: #888888;
        }

        &::before {
            content: "";
            display: none;
            transform: rotate(180deg);
        }
    }
}