.autorization {
    margin-top: 100px;

    &__input-field {
        width: 100%;
        max-height: 56px;
        height: 100%;
        font-family: 'Inter';
        font-size: 14px;
        padding: 16px 20px;
        margin-top: 32px;
        border: 1px solid #F0F0F0;
        border-radius: 16px;

        &::placeholder {
            font-size: 14px;
            font-family: "Inter";
            color: #D9D9D9;
        }

        &_err {
            border: 1px solid #FF4D4F;
            &::after {
                content: "Обязательное поле";
                background-color: #FF4D4F;
                color: #9c2424;
                z-index: 100;
                display: inline-block;
                position: absolute;
                width: 100px;
                height: 16px;
                font-family: "Inter";
                font-size: 12px;
            }
        }
    }
}


.text {
    font-size: 14px;
    font-family: 'Inter';

    &_blue {
        display: inline-block;
        color: #1890FF;
    }

    &_red {
        color: #FF4D4F;
    }
}



.title {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 400;
}

.autorization {

    &__input-field {
        width: 100%;
        max-height: 56px;
        height: 100%;
        font-family: 'Inter';
        font-size: 14px;
        padding: 16px 20px;
        margin-top: 32px;
        border: 1px solid #F0F0F0;
        border-radius: 16px;

        &::placeholder {
            font-size: 14px;
            font-family: "Inter";
            color: #D9D9D9;
        }

        &_err {
            border: 1px solid #FF4D4F;
            &::after {
                content: "Обязательное поле";
                background-color: #FF4D4F;
                color: #9c2424;
                z-index: 100;
                display: inline-block;
                position: absolute;
                width: 100px;
                height: 16px;
                font-family: "Inter";
                font-size: 12px;
            }
        }
    }
}



.text {
    font-size: 14px;
    font-family: 'Inter';

    &_blue {
        display: inline-block;
        color: #1890FF;
    }

    &_red {
        color: #FF4D4F;
    }
}

.title {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 400;
}

.autorization {
 
    &__header {
        display: flex;
        padding: 40px 48px;
        max-width: 1440px;
        justify-content: space-between;
        width: 100%;
        height: auto;
        gap: 32px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        align-items: center;
    }

    &__input-field {
        display: inline-block;
        position: relative;
        width: 100%;
        max-height: 56px;
        height: 100%;
        font-family: 'Inter';
        font-size: 14px;
        padding: 16px 20px;
        margin-top: 32px;
        border: 1px solid #F0F0F0;
        border-radius: 16px;

        &::placeholder {
            font-size: 14px;
            font-family: "Inter";
            color: #D9D9D9;
        }

        &_err {
            display: inline-block;
            position: relative;
            width: 100%;
            max-height: 56px;
            height: 100%;
            font-family: 'Inter';
            font-size: 14px;
            padding: 16px 20px;
            margin-top: 32px;
            border: 1px solid #F0F0F0;
            border-radius: 16px;
            border: 1px solid #FF4D4F;

            &::after {
                content: "Обязательное поле";
                background-color: #FF4D4F;
                color: #9c2424;
                z-index: 100;
                display: inline-block;
                position: absolute;
                width: 100px;
                height: 16px;
                font-family: "Inter";
                font-size: 12px;
            }
        }
    }

    .authorization-checkbox {
        &__container {
            display: flex;
            gap: 10px;
        }
    }

    .authorization-checkbox__text::before {
        border: 1px solid black;
        background-color: inherit;
    }
    
    .authorization-checkbox:checked~.authorization-checkbox__text::before {
        background-image: url("./img/checkbox.png");
        background-color: unset;
    }
}



.text {
    font-size: 14px;
    font-family: 'Inter';

    &_blue {
        display: inline-block;
        color: #1890FF;
    }

    &_red {
        color: #FF4D4F;
    }
}

.title {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 400;
}

.link-box-btn {
    display: inline-block;
    width: 100%;
    max-width: 200px;
}

.right-header-box {

    & .text {
        display: inline-block;
        min-width: 127px;
    }

    & .blue {
        display: inline-block;
        min-width: 127px;
    }
}

.main-loader-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;

    .title {
        font-size: 32px;
        margin-top: 32px;
        font-weight: 600;
        color: #888888;
    }

    &__content {
        margin-top: -150px;
        max-width: 480px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .progress {
        margin-top: 64px;
        width: 100%;
        height: auto;
        border-radius: 16px;

        span {
            border-radius: 16px;
            span {
                border-radius: 16px;
            }
        }
    }
}