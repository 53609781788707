.article-template {
    padding: 16px 24px;
    width: auto;
    height: 100%;
    background-color: white;
    flex-shrink: 1;
    
    &__content {
        flex-shrink: 1;
        max-width: 1200px;
        width: 100%;
        gap: 24px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
       align-items: flex-start;
    }

    &__content-article {
        flex-shrink: 1;
        max-width: 920px;
        width: 100%;
    }

    &__info-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        flex-wrap: wrap;
        gap: 24px;
        margin-top: 8px;
        margin-bottom: 48px;
        margin-left: auto;
        margin-right: auto;
    }

    .block-wrapper {
        position: relative;

        .delete-block-button {
            z-index: 1;
            border: none;
            top: -10px;
            right: -15px;
            background-image: url('./img/close.svg');
            color: #F5F5F5;
            background-repeat: no-repeat;
            width: 18px;
            height: 18px;
            position: absolute;
            background-color: transparent;
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }
    }

    .add-new-block {
        position: relative;
        width: 100%;

        .btn-box {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 16px;
        }

        .popup {
            display: none;
            
            &__visible {
                z-index: 2;
                display: flex;
                flex-direction: row;
                min-width: 150px;
                max-width: 100%;
                left: 10%;
                overflow: scroll;
                position: absolute;
                background-color: #F5F5F5;
                border-radius: 16px;
                -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
                gap: 5px;
            }
        }

        &__button {
            display: flex;
            width: 60px;
            border-radius: 16px;
            margin-left: auto !important;
            margin-right: auto !important;
            justify-content: center;
            border: none;
            padding: 5px;
            margin: 15px 0;
            opacity: 0.5;
    
            &:hover {
                opacity: 1;
            }
        }

        .option {
            border-radius: 16px;
            background-color: white;
            border: none;
            width: 150px;
            height: 100px;
            opacity: 0.7;

            &:hover {
                opacity: 1;
            }
        }
    }

    .text__grey {
        color: #888888;
        font-size: 12px;
    }

    .tags-box {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        align-items: center;
    }

    .blue-box {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #E6F7FF;
        border-radius: 16px;
        padding-left: 16px;
        padding-right: 16px;
        width: auto;
        height: 20px;
    }

    .sticky-box {
        height: 1000px;
       
    }

    .link-box {
       top: 20px;
        height: 400px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 16px;
        width: 256px;
        margin-top: 135px;
        background-color: #F3FFE6;
        border-radius: 16px;
        position: sticky;
        height: auto;

        .link__blue {
            font-size: 14px;
            display: block;
            text-decoration: underline;
        }
    }

    &__img-container {
        display: inline-block;
        margin-top: 24px;
        min-height: 100px;
        margin-left: auto;
        margin-right: auto;
        min-width: 100%;

        &__placeholder {
            width: 200px;
            height: 200px;
        }

        &__button {
            min-width: 100%;
            position: relative;
            top:0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 0;
            border: none;
            border-radius: 16px;
            background-color: #F5F5F5;
            min-height: 100px;
            //opacity: 0.5;

            &:hover:not([disabled]) {
                opacity: 0.5;

                &::after {
                    content: "Выберите изображение";
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 0;
                    left: 0;
                    font-weight: 400;
                    font-size: 32px;
                    width: 100%;
                    height: 100%;
                    border-radius: 16px;
                }
            }

            &__placeholder {
                display: flex;
                width: 400px;
                height: 400px;
                justify-content: center;
                align-items: center;
            }
        }

        &__file-input {
            position: absolute;
            opacity: 0;
            visibility: hidden;
        }

        &__img {
            display: inline-block;
            max-width: 100%;
            width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    .text__blue {
        color: #1890FF;
    }

    .title__medium {
        text-align: center;
        margin-top: 48px;
        font-weight: 400;
        font-size: 32px;
        margin-bottom: 48px;

        &:empty {
            height: 1lh;
    
            &::after{
                content: "Введите заголовок";
                color: #D9D9D9;
            }
        }
    }

    .title__medium[contenteditable="true"] {
        outline: none;
    }


    .title__big {
        text-align: center;
        font-size: 32px; 
    }

    .title__big[contenteditable="true"] {
        outline: none;
    }

    .text {
        margin-top: 24px;
        max-width: 920px;
        letter-spacing: 5%;
        line-height: 1.5;
        font-size: 24px;
        font-weight: 300;
        white-space: pre-wrap;

        &:empty {
            height: 1lh;
    
            &::after{
                content: "Введите текст";
                color: #D9D9D9;
            }
        }
    }

    .title {
        margin-top: 32px;
    }

    .blue-box {

        &__big {
            margin-top: 16px;
            max-width: 920px;
            background-color: #E6F7FF;
            padding: 16px 24px;
            border-radius: 16px;
            display: flex;
            flex-direction: column;
        }

        &__link-and-icon {
            display: flex;
            gap: 16px;
            align-items: start;
        }

        &__items {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            flex-wrap: wrap;
            align-items: center;
        }

        &__item {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .link__blue {
                text-decoration: underline;
                max-width: 320px;
                font-size: 14px;
            }

            .icon__info {
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-top: 5px;
            }
        }


    }

    .article-buttons {
        display: flex;
        justify-content: center;
        gap: 25px;
    }

    .right-aside {
        display: flex;
        flex-direction: column;
        align-items: center;

        .link-box-editable {
            display: flex;
            align-items: center;
            width: auto;
            gap: 8px;

            .text_grey {
                color:#D9D9D9;
                font-size: 14px;
                text-decoration: underline;
            }
        }
    }
}

.title__big {
    font-size: 32px;
    font-weight: 400;
    text-align: center;

    &_grey {
        color: #D9D9D9;
        font-size: 32px;
        font-weight: 400;
        text-align: center;
    }

    &:empty {
        padding-top: 0;
        height: 1lh;

        &::before {
            content: "Введите заголовок";
            color: #D9D9D9;
        }
    }
}

.yellow-information-box {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    max-width: 920px;
    background-color: #F9F6C2;
    border-radius: 16px;
    padding: 16px;
    margin-top: 24px;

    .text {
        font-size: 16px;

        &:empty {
            height: 1lh;
    
            &::after{
                content: "Введите текст";
                color: #c2cef9;
            }
        }
    }

    img {
        display: inline-block;
        margin-top: 4px;
    }

    .text {
        margin: 0;
    }
}