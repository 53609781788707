// контейнер sidebar
.sidebar {
    display: flex;
    flex-shrink: 0;
    flex-shrink: 0;
    flex-direction: column;
    max-width: 274px;
    width: 274px;
    overflow: hidden;
    width: 100%;
    background-color: white;
    height: auto;
    border-right: 1px solid rgba(0, 0, 0, 0.25);
    transition: all 0.3s;

    &_active {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        background-color: white;
        height: auto;
        border-right: 1px solid rgba(0, 0, 0, 0.25);
        max-width: 50px;
        transition: all 0.3s;
    }
    
    &__content {
        display: flex;
        flex-direction: column;
        width: 274px;
        height: auto;
    }
}

// Лого бокс
.logo-box {
    display: flex;
    align-items: center;
    height: 60px;

    & .logo {
        display: inline-block;
        font-family: 'Inter';
        font-size: 16px;
        width: auto;
    }
}

// Кнопка бургер



.settings {
    &__link-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__list {
        display: block;
        overflow: hidden;
        max-height: 1000px;
        transition: max-height .5s;
    }

    &__list_hide {
        display: block;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s;
    }

    &__link {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        &_grey {
            color: #888888;
        }
    }
}