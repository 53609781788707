.article-min {
    width: 320px;
    min-height: 360px;
    height: auto;
    -webkit-box-shadow: 0px 14px 40px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 14px 40px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 14px 40px 0px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    overflow: hidden;

    .img-box {
        display: flex;
        padding: 16px;
        align-items: center;
        justify-content: center;
        height: 160px;
        width: 100%;


        img {
            display: inline-block;
            max-width: 100%;
            width: auto;
            height: auto;
        }
    }

    .content-box {
        height: calc(100% - 160px);
        border-radius: 16px;
        padding: 16px 16px 8px;
        border-top: 1px solid #F0F0F0;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
            padding: 0;
            font-size: 24px;
            font-weight: 500;
        }

        .text {
            font-size: 14px;
        }

        .link__blue {
            font-size: 14px;
            margin-top: auto;
            justify-self: flex-end;
            color: #1890FF;
        }
    }

   
}