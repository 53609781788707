.last-news {
    width: 360px;
    height: auto;
    min-height: 240px;
    padding: 16px 24px;
    background-color: white;
    border-radius: 16px;

    .img-box {
        width: 312px;
        height: 168px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .text {
        margin-top: 16px;
    }

  
}