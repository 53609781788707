.copyright {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 1em;
    width: 100%;
    margin-right: auto;

    & .footer__link {
        border-left: 1px solid #DEDEDE;
        padding-left: 2em;
        padding-right: 2em;
        text-align: center;
    }

    & .country {
        justify-self: flex-end;
        margin-left: auto;
        border-left: none;
        padding-right: 0;
        width: 100px;
    }
}